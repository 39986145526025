<template>
  <div>
    <router-link class="come-back" to="/admin/media/">
      <div></div>
      Вернуться назад
    </router-link>

    <h2 class="title_categories">
      {{ isEdit ? `Редактирование медиа №${$route.params.id}` : 'Создание медиа' }}
    </h2>
    <tricolor-line class="card_containet__tricolor"/>

    <form @submit.prevent="createMedia" ref="createMedia">
      <div>
        <div class="content-sb">
          <p>Заголовок<span>*</span></p>
          <input required placeholder="Введите заголовок" v-model="mediaData.title" type="text" name="title" class="input blue">
        </div>

        <div class="content-sb">
          <p>Заголовок (Англ)</p>
          <input placeholder="Введите заголовок" v-model="mediaData.title_eng" type="text" name="title_eng" class="input blue">
        </div>

        <div class="content-sb">
          <p>Описание</p>
          <input placeholder="Введите описание" v-model="mediaData.description" type="text" name="description" class="input blue">
        </div>

        <div class="content-sb">
          <p>Описание (Англ)</p>
          <input placeholder="Введите описание" v-model="mediaData.description_eng" type="text" name="description_eng" class="input blue">
        </div>

        <div class="content-sb">
          <p>Дата<span>*</span></p>
          <input required placeholder="Введите дату" v-model="mediaData.date" type="date" name="date" class="input blue">
        </div>

        <div class="content-sb">
          <p>Автор</p>
          <input placeholder="Введите автора" v-model="mediaData.author" type="text" name="author" class="input blue">
        </div>

        <div class="content-sb">
          <p>Автор (Англ)</p>
          <input placeholder="Введите автора" v-model="mediaData.author_eng" type="text" name="author_eng" class="input blue">
        </div>

        <div class="content-sb">
          <p>Рубрика<span>*</span></p>
          <select name="rubric" v-model="mediaData.rubric" class="input blue">
            <option v-for="(rubric,index) in getRubrics" :value="rubric.id" :key="`rubric${index}`" :selected="index === 0">
              {{ rubric.title }}
            </option>
          </select>
        </div>

        <div class="content-sb">
          <p>Тип медиа контента<span>*</span></p>
          <select name="rubric" v-model="mediaData.media_type" class="input blue" @input="changeType()">
            <option v-for="(mediaType,index) in getMediaTypes" :value="mediaType.id" :key="`mediaType${index}`">
              {{ mediaType.title }}
            </option>
          </select>
        </div>

        <div class="content-sb">
          <p>Теги</p>
          <tags-list-search :tags="getTagsList" @setTags="setTags"/>
        </div>

        <!--<div class="content-sb">
          <p>Текст</p>
          <ckeditor :editor-url="$root.editorUrl" v-model="mediaData.text" class="wysiwyg"/>
        </div>

        <div class="content-sb">
          <p>Текст (Англ)</p>
          <ckeditor :editor-url="$root.editorUrl" v-model="mediaData.text_eng" class="wysiwyg"/>
        </div>-->
      </div>

      <p>Медиа материалы</p>
      <br/>

      <div v-if="mediaData.media_type === 1">
        <div class="content-sb">
          <p>Превью</p>
          <custom-drop-zone :name="'preview_media'"
                            @setFile="setFile($event, ['mediaData','preview_media'])"
                            :uploaded-blob="previewBlob"/>
        </div>
        <!--
        <div class="content-sb">
          <p>Изображения<span>*</span></p>

          <input :required="!isEdit" multiple placeholder="Загрузите файл" type="file" name="mediaDetail" class="input blue" @input="uploadMediaList($event.target.files)">
        </div>

        <div class="files-preview__container">
          <img :src="file" v-for="(file, index) in filesBlobs" :key="`uploadedFile${index}`">
        </div>-->
        <div class="content-sb" v-for="(media, index) in mediaList" :key="`media${index}`">
          <p>
            Изображение №{{ index + 1 }}
            <span @click="deleteMedia(index)">
              Удалить
            </span>
          </p>
          <p class="hidden-field">
            Приоритет
            <input type="number" class="input blue priority-input" v-model="ordersList[index]">
          </p>
          <custom-drop-zone :name="`mediaFile${index}`"
                            :uploaded-blob="filesBlobs[index]"
                            @setFile="setFile($event, ['mediaList', index])"
                            @setBlob="setFile($event, ['filesBlobs', index])"/>
        </div>
      </div>

      <div class="video-inputs__container" v-else>
        <div class="content-sb">
          <p>Превью</p>
          <custom-drop-zone :name="'preview_media'"
                            @setFile="setFile($event, ['mediaData','preview_media'])"
                            :uploaded-blob="previewBlob"/>
        </div>

        <!--<div class="content-sb">
          <p>Длина превью<span>*</span></p>
          <input placeholder="Введите длину" v-model="mediaData.media_detail" type="text" name="media_detail" class="input blue">
        </div>-->

        <div class="content-sb" v-for="(media, index) in mediaList" :key="`media${index}`">
          <p>
            Ссылка на видео №{{ index + 1 }}
            <span @click="deleteMedia(index)">
              Удалить
            </span>
          </p>
          <p class="hidden-field">
            Приоритет
            <input type="number" class="input blue priority-input" v-model="ordersList[index]">
          </p>
          <input placeholder="Введите ссылку на видео" v-model="mediaList[index]" type="text" class="input blue video-input" required>
        </div>
      </div>

      <div class="btn-blue" @click="addMedia()">
        Добавить {{ mediaData.media_type === 1 ? 'изображение' : 'ссылку на видео' }}
      </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/media" class="btn-white">Отмена</router-link>

        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import CustomDropZone from '@/components/Admin/CustomDropZone'
import TagsListSearch from '@/components/news/TagsListSearch'

export default {
  name: 'CreateMedia',

  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      previewBlob: null,
      mediaData: {
        title: null,
        title_eng: null,
        description: null,
        description_eng: null,
        date: null,
        preview_media: null,
        author: null,
        author_eng: null,
        text: null,
        text_eng: null,
        rubric: 1,
        media_type: 1,
        media_detail: '1',
        tags_list: null
      },
      mediaList: [],
      filesBlobs: [],
      ordersList: []
    }
  },

  watch: {
    getCurrentMedia () {
      const dataFromServer = this.getCurrentMedia

      this.mediaData = {
        title: dataFromServer.title,
        title_eng: dataFromServer.title_eng,
        description: dataFromServer.description,
        description_eng: dataFromServer.description_eng,
        date: dataFromServer.date.split(' ').join('T'),
        author: dataFromServer.author,
        author_eng: dataFromServer.author_eng,
        text: dataFromServer.text,
        text_eng: dataFromServer.text_eng,
        rubric: dataFromServer.rubric,
        media_type: dataFromServer.media_type,
        media_detail: dataFromServer.media_detail,
        tags_list: dataFromServer.tags_list ? dataFromServer.tags_list.join('/') : '',
        preview_media: null
      }

      this.mediaList = dataFromServer.media_list.map((media) => {
        if (dataFromServer.media_type_model.code === 'PHOTO_REPORT') {
          const link = media.link.split('/')
          return link[link.length - 1]
        } else {
          return media.link
        }
      })

      this.filesBlobs = dataFromServer.media_list.map((media) => {
        return media.link
      })

      this.ordersList = dataFromServer.media_list.map((media) => {
        return media.order
      })

      if (dataFromServer.preview_media) {
        const preview = dataFromServer.preview_media.split('/')
        this.mediaData.preview_media = preview[preview.length - 1]
        this.previewBlob = dataFromServer.preview_media
      }

      this.$forceUpdate()
    }
  },

  created () {
    if (this.isEdit) {
      this.$store.dispatch('getCurrentAdminMedia', this.$route.params.id)
    }

    this.$store.dispatch('getMedias')
    this.$store.dispatch('getMediaTypes')
    this.$store.dispatch('getRubrics')
  },

  methods: {
    uploadMediaList (files) {
      this.filesBlobs = []
      this.mediaList = []
      this.ordersList = [];

      [...files].forEach((file) => {
        this.filesBlobs.push(window.URL.createObjectURL(file))
        this.mediaList.push(file)
        this.ordersList.push(1000)
      })

      this.$forceUpdate()
    },

    createMedia () {
      const formData = new FormData()

      for (const key in this.mediaData) {
        if (this.mediaData[key] !== null) {
          formData.append(key, this.mediaData[key])
        }
      }

      this.mediaList.forEach((media, index) => {
        formData.append(`media_list[${index}]`, media)
      })

      this.ordersList.forEach((order, index) => {
        formData.append(`media_orders[${index}]`, order)
      })

      console.debug(...formData)

      if (!this.isEdit) {
        this.$store.dispatch('createMedia', formData)
      } else {
        formData.append('_method', 'PUT')
        this.$store.dispatch('updateMedia', {
          data: formData,
          id: this.$route.params.id
        })
      }
    },

    setFile (file, key) {
      if (key.length === 1) {
        this[key[0]] = file
      } else if (key.length === 2) {
        if (key[0] === 'mediaData') {
          this.previewBlob = null
        }
        this[key[0]][key[1]] = file
      }
      this.$forceUpdate()
    },

    addMedia () {
      this.mediaList.push(null)
      this.ordersList.push(1000)
      this.filesBlobs.push(null)
    },

    deleteMedia (index) {
      this.mediaList.splice(index, 1)
      this.ordersList.splice(index, 1)
      this.filesBlobs.splice(index, 1)
    },

    changeType () {
      this.mediaList = []
      this.filesBlobs = []
      this.mediaData.preview_media = null
    },

    setTags (tags) {
      this.mediaData.tags_list = tags.join('/')
    }

  },
  computed: {

    getRubrics () {
      return this.$store.getters.getRubrics
    },

    getMedias () {
      return this.$store.getters.getMedias
    },

    getMediaTypes () {
      return this.$store.getters.getMediaTypes
    },

    getCurrentMedia () {
      return this.$store.getters.getCurrentAdminMedia
    },

    getTagsList () {
      if (this.mediaData.tags_list) {
        return this.mediaData.tags_list.split('/').filter(tag => !!tag)
      } else {
        return []
      }
    }

  },
  components: {
    TagsListSearch,
    CustomDropZone,
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  .files-preview__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > img {
      height: 8rem;
      width: 10rem;
      object-fit: cover;
      object-position: center;
      margin-right: 1rem;
      border-radius: .5rem;
    }
  }

  .hidden-field {
    display: none;
  }

  .content-sb .priority-input {
    width: 6rem;
  }

  .content-sb .video-input {
    width: 15rem;
  }

  .video-inputs__container {
    display: flex;
    flex-direction: column;

    > input {
      margin-bottom: 1rem;
    }
  }

  .wysiwyg {
    width: 33.75rem;
  }

  .btns-create-form {
    border-top: 1px solid #D5D6D9;
    padding-top: 2.5rem;
    margin-top: 2.5rem;
  }

  .content-sb {
    margin-bottom: 1.25rem;

    span {
      color: red;
    }
  }

  .select, .input {
    width: 33.75rem;
  }

  .inputs__container {
    margin-bottom: 2rem;
    border-bottom: 1px solid #D5D6D9;
  }

  .content-blocks__container {
    display: flex;
    flex-direction: column;

    > .content-block {
      margin-bottom: 1.875rem;

      header {
        margin-bottom: .75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h4 {
          font-size: .875rem;
          line-height: 1.375rem;
          color: $mainFontColor;
        }

        > img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
          cursor: pointer;
        }
      }

      .data-card {
        background: white;
        border: 1px solid #EBF3FF;
        box-sizing: border-box;
        box-shadow: 0 .9375rem 1.5625rem rgba(189, 205, 221, 0.15);
        border-radius: .5rem;
        padding: 1.25rem;
        width: 100%;
        min-height: 8.875rem;
      }
    }
  }

  .add-content-block__container {
    background-color: $neutral-placeholder;
    width: 100%;
    padding: 1.25rem;
    border-radius: .187rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: .5rem;

    &:after {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      background-color: $neutral-placeholder;
      top: 0;
      left: 50%;
      transform: translate(-50%,-50%) rotate(45deg);
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.25rem;
    }

    > .btn-blue > img {
      width: 1.125rem;
      height: 1.125rem;
      object-fit: contain;
      object-position: center;
      margin-right: .5rem;
    }
  }
</style>
